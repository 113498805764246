<template>
  <div>
    <b-card v-if="showOption">
      <div class="text-black mb-2 text-2xl font-semibold">
        Metode Pickup
      </div>
      <b-row>
        <b-col md="4">
          <b-card
            img-src="https://storage.googleapis.com/komerce/assets/illustration/pickup-komship.svg"
            style="border:1px solid #E2E2E2;border-radius:16px;"
            img-alt="Input Cepat"
            img-top
            body-class="text-center"
          >
            <b-card-title class="text-black text-2xl !mb-[8px] font-semibold">
              Pickup Order Komship
            </b-card-title>
            <b-card-text class="min-h-[50px]">
              Pickup untuk orderan dari Komship
            </b-card-text>
            <b-button
              variant="primary"
              @click="pickupKomship = true, showOption = false"
            >
              Ajukan Pickup & Packing
            </b-button>
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card
            img-src="https://storage.googleapis.com/komerce/assets/illustration/packing-nonkomship.svg"
            style="border:1px solid #E2E2E2;border-radius:16px"
            img-alt="Mutasi Rekening"
            img-top
            body-class="text-center"
          >
            <b-card-title class="text-black text-2xl !mb-[8px] font-semibold">
              Packing Order Non-Komship
            </b-card-title>
            <b-card-text class="min-h-[50px]">
              Request packing untuk orderan yang tidak kamu buat melalui Komship
            </b-card-text>
            <b-button
              variant="primary"
              @click="packingKompack = true, showOption = false"
            >
              Ajukan Packing
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <div v-if="pickupKomship">
      <PickupRequest
        :user-data="userData"
        :back-button="backButton"
        @back="showOption = true, pickupKomship = false"
      />
    </div>
    <div v-if="packingKompack">
      <Packing
        :user-data="userData"
        :back-button="backButton"
        @back="showOption = true, packingKompack = false"
      />
    </div>
  </div>
</template>

<script>
import secureLs from '@/libs/secureLs'
import PickupRequest from '../komship/pickup/PickupRequest'
import Packing from '../kompack/packing/Packing.vue'

export default {
  components: {
    PickupRequest,
    Packing,
  },
  data() {
    return {
      userData: secureLs.getItem('userData'),

      pickupKomship: false,
      packingKompack: false,

      showOption: false,
      backButton: false,
    }
  },
  created() {
    if (this.userData.is_komship === 1 && this.userData.is_kompack === 1) {
      this.showOption = true
      this.backButton = true
    } else if (this.userData.is_komship === 1 && this.userData.is_kompack === 0) {
      this.pickupKomship = true
    } else if (this.userData.is_komship === 0 && this.userData.is_kompack === 1) {
      this.packingKompack = true
    }
  },
}
</script>

<style scoped>
img { position: relative; z-index: 100; }
</style>
